import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

import IndexLayout from 'components/IndexLayout'
import SEO from 'components/seo'
import {
  StripeCheckoutRedirectContainer,
  ShapeContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectHeader,
  StripeCheckoutImage,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
} from 'components/StripeCheckout'
import LottieShape from 'components/animations/LottieShape'
import AbstractPaymentAnimation from 'components/animations/abstract/AbstractPaymentAnimation'
import cancel from 'images/png/cancel.png'

const Cancel = () => {
  const { t } = useTranslation('paymentCancel')

  return (
    <IndexLayout>
      <SEO title={t('title')} />
      <StripeCheckoutRedirectContainer>
        <ShapeContainer>
          <LottieShape size={500} animationData={AbstractPaymentAnimation} />
        </ShapeContainer>
        <StripeCheckoutContentContainer>
          <StripeCheckoutRedirectHeader>
            <StripeCheckoutImage src={cancel} alt={t('altImageCheckout')} />
            {t('heading')}
          </StripeCheckoutRedirectHeader>
          <StripeCheckoutRedirectDescription>
            {t('message')}
          </StripeCheckoutRedirectDescription>
          <Link
            css={`
              width: 300px;
            `}
            style={{ boxShadow: 'none' }}
            to="/#pricing"
          >
            <StripeCheckoutRedirectButton>
              {t('actionButton')}
            </StripeCheckoutRedirectButton>
          </Link>
        </StripeCheckoutContentContainer>
      </StripeCheckoutRedirectContainer>
    </IndexLayout>
  )
}

export default Cancel
